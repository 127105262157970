exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-client-scenario-js": () => import("./../../../src/pages/client-scenario.js" /* webpackChunkName: "component---src-pages-client-scenario-js" */),
  "component---src-pages-get-in-touch-js": () => import("./../../../src/pages/get-in-touch.js" /* webpackChunkName: "component---src-pages-get-in-touch-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-methodology-js": () => import("./../../../src/pages/methodology.js" /* webpackChunkName: "component---src-pages-methodology-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */),
  "component---src-pages-who-we-work-with-js": () => import("./../../../src/pages/who-we-work-with.js" /* webpackChunkName: "component---src-pages-who-we-work-with-js" */)
}

